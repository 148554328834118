<template>
    <div>
        <el-dialog title="变色" :visible.sync="show" width="30%">
            <el-form :model="form">
                <el-form-item :label-width="'auto'" label="变色时间（小时）">
                    <el-input-number v-model="form.hours" autocomplete="on"></el-input-number>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="show = false">取 消</el-button>
                <el-button type="primary" @click="cooperationTo">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    inject: ["getlist"],
    data() {
        return {
            form: {
                hours: 24,
            },
            show: false,
            row: {},
        };
    },
    methods: {
        async cooperationTo() {
            let { data: res } = await this.$http.post("/admin/Cooperation/changeColor", {
                id: this.row.id,
                ...this.form,
            });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.show = false;
                this.getlist();
            }
        },
        init(val) {
            this.row = val;
            this.show = true;
        },
    },
};
</script>

<style lang="less" scoped></style>
