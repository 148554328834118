export const recommendList = [
  {
    key: 0,
    value: '不推荐'
  },
  {
    key: 1,
    value: '推荐'
  }
]
